/* NORMALIZATION */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

*,
*:focus,
*:hover {
    outline: none;
}

/* Kendo */
.k-input {
    font-family: Arial, Arial, Helvetica, sans-serif;
}

.k-radio-label {
    font-family: Arial, Arial, Helvetica, sans-serif !important;
    font-weight: 400 !important;
    text-transform: none !important;
}

/* Site */
html,
body {
    width: 100%;
    height: 100%;
    margin: 0;
    padding: 0;
    background: #f8f8f8;
    font-family: Arial, Helvetica, sans-serif;
    color: #333;
}

#app {
    min-height: 100%;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
}

    #app header {
        flex: 0 0 auto;
        max-width: 100%;
        min-width: 100%;
    }

    #app main {
        flex: 1 1 auto;
        max-width: 100%;
        min-width: 100%;
    }

/* HEADER */
#tenant-select {
    min-height: 2.875rem;
    margin: 0 0 1.2rem;
    padding: .5rem 5% .5rem 0;
    box-sizing: border-box;
    text-align: right;
    background: #555;
}

.navigation {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 90%;
    margin: 0 auto 1.2rem;
}

    .navigation #logo {
        flex: 0 0 20%;
    }

        .navigation #logo img {
            max-width: 20.4rem;
            max-height: 4.8rem;
        }

    .navigation nav {
        flex: 0 1 auto;
        text-align: right;
        z-index: 999;
        font-family: Roboto,Arial,Helvetica,sans-serif;
    }

    .navigation nav ul {
        margin: 0;
        padding: 0;
        list-style: none;
    }
        .navigation nav li ul {
            display: none;
            padding: 1rem .75rem;
            background: #fff;
            border: 1px solid #d7d7d7;
            text-align: left;
        }

        .navigation nav li:hover ul {
            display: block;
            position: absolute;
        }

        .navigation nav ul li li {
            margin: 0;
            display: block;
        }

            .navigation nav li li + li {
                margin-top: 1rem;
            }

            .navigation nav ul li {
                display: inline-block;
                margin-left: 1.5rem;
                font-size: 1rem;
                font-weight: bold;
                text-transform: uppercase;
            }

/* MAIN */
.title {
    height: 4.25rem;
    background-color: #f0f0f0;
    border-top: 1px solid #d7d7d7;
    display: flex;
    justify-content: space-around;
    align-items: center;
}

    .title h1 {
        flex: 0 0 60%;
        margin: 0;
        font-weight: 500;
        font-size: 32px;
        font-family: Roboto, Arial, Arial, Helvetica, sans-serif;
        text-transform: uppercase;
    }

    .title .controls {
        flex: 0 0 20%;
        text-align: right;
    }

    .title .controls button + button {
        margin-left: .5rem;
    }

.page {
    margin-bottom: 2rem;
    padding-top: 2rem;
    background-image: linear-gradient(#d6d6d6, rgba(255,0,0,0) 2.5rem)
}

    .page .grid,
    .page .content {
        width: 90%;
        margin: 0 auto;
    }

    .page .grid {
        min-height: 25rem;
    }

        .page .content > div {
            margin: 1rem 0;
        }

    .page h2 {
        margin: 0 0 .5rem;
        padding: .75rem 1rem;
        background-color: #dfdfdf;
        font-family: Roboto, Arial, Helvetica, sans-serif;
        font-weight: 500;
        font-size: 1.1rem;
        text-transform: uppercase;
    }

    .page label {
        display: block;
        font-size: .8rem;
        font-weight: 700;
        font-family: Roboto, Arial, sans-serif;
        text-transform: uppercase;
    }

    .page .k-textbox,
    .page .k-textarea,
    .page .k-upload,
    .page .k-multiselect,
    .page .k-editor {
        width: 40rem;
    }

    .page table td + td {
        padding-left: 1rem;
    }

    .page .delete {
        margin-top: 2rem;
    }

    .page .grid button + button {
        margin-left: .75rem;
    }

.k-grid td.column-right {
    text-align: right;
}

table.notification-document-list {
    width: 100%;
    margin-top: 1rem;
    font-size: .8rem;
}

table.notification-document-list,
table.notification-document-list th,
table.notification-document-list td {
    border: 1px solid #ccc;
    border-collapse: collapse;
}

    table.notification-document-list th,
    table.notification-document-list td {
        padding: .35rem !important;
        font-weight: normal;
        text-align: left;
    }

    table.notification-document-list th {
        background-color: #dfdfdf;
    }
    /* GENERAL */
    a,
    .link,
    input[type="submit"],
    input[type="button"],
    button {
        cursor: pointer;
        text-decoration: none;
        color: black;
    }

input[type="radio"] {
    border-color: #555;
}

    a:hover,
    .link:hover,
    .selected {
        color: red;
    }


.k-dialog .page {
    margin: 1rem 0 2rem;
    padding: 0;
    background: none;
}

.k-dialog .page h2 {
    padding: .5rem .75rem;
}

.k-animation-container {
    z-index: 99999;
}

.required {
    color: #f00;
    font-size: 0.7rem;
}

#notifications-page th:first-child div div {
    display: none !important;
}

.k-grid .warn,
.k-grid .warn ~ td,
.notification-document-list tr.warn {
    background-color: #fcc;
}

.notification-document-list tr.warn:hover td:first-child:before {
    content: "This document is not in a selected group. Notifications for this document will not be sent.";
    position: absolute;
    padding: .5rem 1.5rem;
    background: #fff;
    border: 1px solid #ccc;
}

.properties > div {
    font-size: .8rem;
    font-weight: 700;
    font-family: Roboto, Arial, sans-serif;
    text-transform: uppercase;
}

.properties > div + div {
    margin-top: 1rem;
}

/* FILE UPLOAD */
ul.k-upload-files.k-reset {
    position: absolute;
    top: 0;
    left: 0;
    background-color: #fff;
    width: 100%;
}